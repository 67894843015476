import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/stylus/app.styl';

Vue.use(Vuetify, {
    theme: {
        primary: '#1976D2', //Azul
        //primary: '#B71C1C', //Rojo
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
    },
    options: {
        customProperties: true
    },
    iconfont: 'md',
});